import { InputHTMLAttributes } from "react";
import { ExclamationCircleIcon } from "@heroicons/react/outline";
import classnames from "classnames";

export interface FloatingInputProps {
  /**
   * https://www.w3schools.com/html/html_form_input_types.asp
   */
  type: string; //TODO: Should type this since there are known types
  /**
   * Name & Id for the html element. Also used for autocomplete.
   */
  name: string;
  /**
   * The label for the input
   */
  label: string;
  /**
   * If the input should be in an error state
   */
  isError?: boolean;
  /**
   * The error message
   */
  errorMessage?: string;
  /**
   * Help text displayed below the input
   */
  hint?: string;
}

export const FloatingInput = ({
  isError = false,
  type,
  name,
  label,
  errorMessage,
  hint,
  className,
  ...props
}: FloatingInputProps & InputHTMLAttributes<HTMLInputElement>) => {
  isError = Boolean(isError);

  const inputStyles = classnames(
    "form-input",
    "peer",
    "placeholder-transparent",
    "block",
    "w-full",
    "px-4 py-3",
    "text-gray-700",
    "transition-all duration-150 ease-out",
    { "border-gray-300": !isError },
    { "border-danger-500": isError },
    "rounded-md",
    "shadow-sm",
    "outline-none",
    "disabled:bg-gray-300",
    { "text-danger-600": isError },
    { "focus:border-brand-700": !isError },
    { "focus:border-danger-500": isError },
    { "focus:ring-brand-700": !isError },
    { "focus:ring-danger-500": isError },
    "text-sm md:text-base"
  );

  const labelStyles = classnames(
    "absolute",
    "left-0",
    "py-0 px-1",
    "my-0 mx-2",
    "text-sm",
    { "text-gray-500": !isError },
    { "text-danger-400": isError },
    "transition-all duration-150 ease-out",
    "pointer-events-none",
    "pointer-text",
    "-top-2.5",
    "peer-focus:-top-2.5",
    { "peer-focus:text-danger-500": isError },
    { "peer-focus:text-brand-700": !isError },
    "peer-focus:text-sm",
    "bg-white",
    "peer-placeholder-shown:text-base",
    "peer-placeholder-shown:top-2.5"
  );

  const messageStyles = classnames(
    "mt-1",
    "px-2",
    "text-sm",
    "font-sans",
    "transition-all duration-150 ease-out",
    { "text-gray-500": !isError },
    { "text-danger-400": isError }
  );

  return (
    <div className={className}>
      <div className="relative">
        <input
          aria-invalid={isError}
          autoComplete={name}
          className={inputStyles}
          id={name}
          name={name}
          placeholder={label}
          type={type}
          {...props}
        />
        <label className={labelStyles} htmlFor={name}>
          {label}
        </label>

        {isError && (
          <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
            <ExclamationCircleIcon
              aria-hidden="true"
              className="w-5 h-5 text-red-500"
            />
          </div>
        )}
      </div>
      <p className={messageStyles}>{isError ? errorMessage : hint}</p>
    </div>
  );
};
